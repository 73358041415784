/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * DS206: Consider reworking classes to avoid initClass
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/main/docs/suggestions.md
 */
const { Pjax } = window

// Keep track of whether or not the preview is being shown currently to prevent
// trying to show it twice.
window.showingPreview = false

const shouldClose = function () {
  const saving = window.savingPreview
  return (typeof saving !== "undefined" && !saving) || window.closePreview
}

class Preview {
  static initClass() {
    this.prototype.MAX_PAGES_INTERVAL = 10
    this.prototype.PROFILE_PJAX_OPTIONS = {
      container: Pjax.targetContainer("profile"),
    }
  }

  constructor(el) {
    let url
    this.addPendingEvents = this.addPendingEvents.bind(this)
    this.closeForEscape = this.closeForEscape.bind(this)
    this.close = this.close.bind(this)
    this.startSave = this.startSave.bind(this)
    this.afterSave = this.afterSave.bind(this)
    this.poll = this.poll.bind(this)
    this.reload = this.reload.bind(this)
    this.pdfRendered = this.pdfRendered.bind(this)
    this.pdfLoaded = this.pdfLoaded.bind(this)
    this.renderPdf = this.renderPdf.bind(this)
    this.pollFields = this.pollFields.bind(this)
    this.scrollSpy = this.scrollSpy.bind(this)
    this.pdfLoadPage = this.pdfLoadPage.bind(this)
    this.renderPage = this.renderPage.bind(this)
    this.$container = $(Pjax.targetContainer("preview"))
    this.$el = $(el)

    // When saving forms rendered via preview, the new preview should only be
    // re-rendered if there was a form error.
    if (shouldClose()) {
      delete window.savingPreview
      delete window.closePreview
      this.close()
      return
    }

    this.renderingPage = 0
    this.fullyRenderedPdf = false

    this.$source = this.$el.find(".preview-source")
    this.$content = this.$el.find(".preview-source-content")
    this.$pageContainer =
      this.$content.find(".page-container").length === 0
        ? this.$content
        : this.$content.find(".page-container")
    this.canSave = this.$el.data().pjaxSubmit !== undefined
    this.fileType = this.$el.data("preview-type")

    if (this.isPending()) {
      this.pollID = this.poll()
      this.addPendingEvents()
    }

    if (this.fileType === "pdf" && (url = this.url())) {
      if (typeof PDFJS === "undefined") {
        require("../../../public/pdfjs/pdf.js")
      }
      PDFJS.workerSrc = "/pdfjs/pdf.worker.js"
      PDFJS.disableCreateObjectURL = true

      this.loadPdf(url)
    } else if (!this.isPending()) {
      this.addEvents()
    }
  }

  static selector(container) {
    return `[data-preview='${container}']`
  }

  addEvents() {
    $("body").on("keyup", this.closeForEscape)
    this.$el.find("[data-action=close]").on("click", this.close)
    $("[data-pjax-container=preview]").on("pjax:start", this.startSave)
    $("[data-pjax-container=preview]").on("pjax:end", this.afterSave)
    return this.$el.trigger("preview:open", this)
  }

  addPendingEvents() {
    $("body").on("keyup", function (e) {
      if (Keyboard.keyMatchesName(e.which, "esc")) {
        return (window.closePreview = true)
      }
    })

    return this.$el.find("[data-action=close]").on("click", () => (window.closePreview = true))
  }

  closeForEscape(e) {
    if (Keyboard.keyMatchesName(e.which, "esc")) {
      return this.close()
    }
  }

  reloadMainContent(saveEvent) {
    if (saveEvent) {
      if (window.location.href.indexOf("onboarding/required_documents") > 0) {
        const pjax_options = $.extend(
          {
            url: window.location.href.replace("/required_documents", ""),
          },
          this.PROFILE_PJAX_OPTIONS,
        )
        return $.pjax(pjax_options)
      } else {
        return $.pjax.reload(this.PROFILE_PJAX_OPTIONS)
      }
    } else {
      return $.Deferred().resolve()
    }
  }

  close(e, saveEvent) {
    saveEvent ??= false
    e?.preventDefault()
    window.showingPreview = false
    clearTimeout(this.pollID)
    this.$container.off()

    const previewContainer = $("[data-preview]")

    return $.when(this.reloadMainContent(saveEvent)).done(() =>
      previewContainer.fadeOut(function () {
        previewContainer.remove()
        if (saveEvent) {
          const pjax_options = { container: Pjax.targetContainer("profile") }
          if (window.location.href.indexOf("onboarding/required_documents") > 0) {
            pjax_options["url"] = window.location.href.replace("/required_documents", "")
            return $.pjax(pjax_options)
          } else {
            return $.pjax.reload(pjax_options)
          }
        }
      }),
    )
  }

  startSave(e, _xhr, options) {
    if (!$(options.target).is("#new_person_document")) {
      return
    }
    const $button = this.$el.find("[type=submit]")
    if (!!$button[0]) {
      ButtonHelpers.setButtonState($button, "disabled")
    }
    return (window.savingPreview = true)
  }

  afterSave(e, _contents, options) {
    if (!$(options.target).is("#new_person_document")) {
      return
    }
    if (this.$container.find(".form-error-messages").length === 0) {
      window.savingPreview = false
      // Also close all modals that might be open behind the preview
      if (window.currentModal !== undefined) {
        window.currentModal.close()
      }
      return this.close(e, true)
    } else {
      return delete window.savingPreview
    }
  }

  isPending() {
    return this.$content.find("[data-status=pending]").length > 0
  }

  poll(time) {
    time ??= 1500
    return setTimeout(this.reload, time)
  }

  reload() {
    return this.$el.find("[data-pjax-click=preview]").click()
  }

  pdfRendered() {
    if (this.canSave) {
      this.addSaveEvents()
    }
    return this.$el.trigger("preview:open")
  }

  loadPdf() {
    const url = this.$el.find("[data-preview-url]").attr("href")
    if (!url) {
      return $.Deferred().resolve()
    }

    const pdfDoc = PDFJS.getDocument(url, undefined, () => {
      console.error("PDF file is password protected")
      return this.addEvents()
    })
    return pdfDoc.then(this.pdfLoaded).then(this.pdfRendered)
  }

  url() {
    return (this.pdfUrl ||= this.$el.find("[data-preview-url]").attr("href"))
  }

  addSaveEvents() {
    this.$container.on("pjax:start", this.startSave)
    return this.$container.on("pjax:beforeReplace", this.afterSave)
  }

  pdfLoaded(pdf) {
    this.pdf = pdf
    this.pageCount = pdf.numPages

    this.maxPagesToShow = this.MAX_PAGES_INTERVAL // Adjusted on scroll
    this.$source.scroll(this.scrollSpy)
    return this.renderPdf()
  }

  renderPdf() {
    if (this.$pageContainer.find("[data-status=pdf_fields_loading]").length > 0) {
      return this.pollFields()
    } else {
      this.pdfLoadPage()
      return this.addEvents()
    }
  }

  pollFields(time) {
    time ??= 500
    return setTimeout(this.renderPdf, time)
  }

  // Spys on the scroll, and at 85% scrolled down begins loading more pages
  scrollSpy(e) {
    if (this.fullyRenderedPdf) {
      return
    }

    const scroll = this.$source.scrollTop()
    const height = this.$content[0].scrollHeight - $(window).height()
    const scrollPercentage = (scroll / height) * 100

    if (scrollPercentage >= 85) {
      const newMax = this.maxPagesToShow + this.MAX_PAGES_INTERVAL
      this.maxPagesToShow = Math.min(newMax, this.pageCount)
      return this.pdfLoadPage(this.currentPageNumber + 1)
    }
  }

  pdfLoadPage(num) {
    num ??= 1
    if (num > this.pageCount || num > this.maxPagesToShow) {
      return
    }

    const currentPage = this.pdf.getPage(num)
    return currentPage.then(this.renderPage)
  }

  renderPage(page) {
    let options
    this.currentPageNumber = page.pageNumber
    if (this.renderingPage >= this.currentPageNumber) {
      return
    }

    this.renderingPage = this.currentPageNumber
    if (this.currentPageNumber === this.pageCount) {
      this.fullyRenderedPdf = true
    }

    // TODO: Make this adjust wider on resize of window
    const canvasWidth = 975
    const scale = canvasWidth / page.getViewport(1.0).width
    const viewport = page.getViewport(scale)
    const $canvas = PdfRender.buildCanvas(page, canvasWidth, this.currentPageNumber)
    $canvas.attr("id", `page${this.currentPageNumber}`)

    if (this.$pageContainer.find(".page.page" + page.pageNumber).length === 0) {
      options = { class: "page page" + page.pageNumber }
      this.$pageContainer.append($("<div/>", options))
    }

    const $page = this.$pageContainer.find(".page" + page.pageNumber)

    if (
      this.$pageContainer.find("[data-pjax-submit=pdf_fields]").length === 1 &&
      $page.find("[data-pjax-container]").length === 0
    ) {
      options = { "data-pjax-container": "pdf_fields_" + page.pageNumber }
      $page.append($("<div/>", options))
    }

    $page.append($canvas)
    $page.data("viewport", viewport)

    const renderContext = PdfRender.getPageRenderContext($canvas, page)

    page.render(renderContext).then(() => {
      PdfRender.positionFields(page)
      PdfRender.setupAnnotations(
        page,
        renderContext["viewport"],
        $canvas[0],
        this.currentPageNumber,
      )
      return this.pdfLoadPage(this.currentPageNumber + 1)
    })

    return this.$el.trigger("preview:renderPage", [this, page])
  }
}
Preview.initClass()

window.Preview = Preview

$.onmount("[data-preview]", function () {
  if (window.showingPreview) {
    return
  }

  window.showingPreview = true
  return new Preview(this)
})
