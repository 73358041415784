/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/main/docs/suggestions.md
 */
class PdfRender {
  static setScale(page, width) {
    return (this.scale = width / page.getViewport(1.0).width)
  }

  // Builds canvas element for rendering a PDF page
  static buildCanvas(page, width, num) {
    this.setScale(page, width)

    const $el = $("<canvas/>")
    $el[0].width = width
    $el[0].height = page.getViewport(this.scale).height

    return $el
  }

  // Builds rendering context for pdf.js
  static getPageRenderContext($el, page) {
    return {
      canvasContext: $el[0].getContext("2d"),
      viewport: page.getViewport(this.scale),
    }
  }

  static constructor(file) {
    this.file = file
    this.pageCount = this.file.numPages
    this.pagesRendered = 1
    this.$fileContainer = $("<div/>")
    return (this.promise = new $.Deferred())
  }

  static addPage(page) {
    const $el = this.buildCanvas(page, 700, this.pagesRendered)
    this.$fileContainer.append($el)
    return page.render(this.getPageRenderContext($el, page))
  }

  static addForPrint() {
    if (this.pagesRendered <= this.pageCount) {
      return this.file.getPage(this.pagesRendered).then((page) => {
        return this.addPage(page).then(() => {
          this.pagesRendered++
          if (this.pagesRendered > this.pageCount) {
            this.promise.resolve(this.$fileContainer)
          }
          return this.addForPrint()
        })
      })
    }
  }

  static positionFields(page) {
    const { scale } = this
    const viewport = page.getViewport(scale)
    return _.each($(`.page${page.pageNumber} .onboarding-form-field`), function (field) {
      const $field = $(field)
      const position = $field.position()
      const point = viewport.convertToViewportPoint(position.left, position.top)
      return $field.css({
        height: parseInt($field.css("height")) * scale + "px",
        width: parseInt($field.css("width")) * scale + "px",
        top: point[1] + "px",
        left: point[0] + "px",
      })
    })
  }

  static setupAnnotations(page, viewport, canvas) {
    const annotationClass = `annotationLayer annotations-${this.currentPageNumber}`
    const $annotationLayerDiv = $("<div />", { class: annotationClass })
    $(canvas).parent().append($annotationLayerDiv)

    const promise = page.getAnnotations().then(function (annotationsData) {
      viewport = viewport.clone({
        dontFlip: true,
      })

      return _.each(annotationsData, function (data) {
        if (!data || !data.hasHtml) {
          return
        }
        if (data.subtype === "Link" && !data.url) {
          return
        }

        const element = PDFJS.AnnotationUtils.getHtmlElement(data, page.commonObjs)
        $(element).find("a").prop("target", "_blank")

        let { rect } = data
        const { view } = page
        rect = PDFJS.Util.normalizeRect([
          rect[0],
          view[3] - rect[1] + view[1],
          rect[2],
          view[3] - rect[3] + view[1],
        ])
        element.style.left = rect[0] + "px"
        element.style.top = rect[1] + "px"
        element.style.position = "absolute"

        const { transform } = viewport
        const transformStr = "matrix(" + transform.join(",") + ")"
        element.style.transform = transformStr
        const transformOriginStr = -rect[0] + "px " + -rect[1] + "px"
        element.style.transformOrigin = transformOriginStr

        return $annotationLayerDiv.append(element)
      })
    })

    return promise
  }
}

window.PdfRender = PdfRender
